import React from 'react';
import {Button, message, Select} from 'antd';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import moment from 'moment';
import {
  CONTACT_FORM_STATUS,
  FORETASTE_STATUS,
  ORDER_STATUS,
} from '../../domain/Constants';
import AnalysisSection from './AnalysisSection';

function DashboardHomePage(props) {
  const app = React.useContext(AppContext.Context);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const _fetchData = async () => {
      const hide = message.loading('更新資料中...');

      try {
        let _pendingForetastOrders = await app.actions.staffGetForetasteOrders({
          query: {
            status: FORETASTE_STATUS.pending,
          },
        });

        let _pendingContactForms = await app.actions.staffGetContactForms({
          query: {
            status: CONTACT_FORM_STATUS.pending,
          },
        });
        setData({
          pendingForetasteOrderCount: _pendingForetastOrders.length,
          pendingContactFormCount: _pendingContactForms.length,
        });
      } catch (err) {}
      hide();
    };
    if (app.staffUser) {
      _fetchData();
    }
  }, [app.staffUser]);

  return (
    <Wrapper>
      <h2>總覽</h2>

      {data && (
        <div className="section">
          <div className="content">
            <div className="block" style={{marginBottom: 10}}>
              目前有
              <span style={{fontSize: 18, margin: '0px 5px'}}>
                {data.pendingForetasteOrderCount}
              </span>
              筆試吃表單未處理
            </div>
            <div className="block">
              目前有
              <span style={{fontSize: 18, margin: '0px 5px'}}>
                {data.pendingContactFormCount}
              </span>
              筆試聯絡表單未處理
            </div>
          </div>
        </div>
      )}

      <div className="section">
        <div className="content">
          <AnalysisSection />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  & h2 {
    margin-bottom: 16px;
  }

  & > .section {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 10px #cecece;
    padding: 20px;
    margin-bottom: 20px;

    & > .actions {
      display: flex;
      margin-bottom: 10px;
    }
    & > .content {
      & .block {
        & .title {
          font-size: 18px;
        }
        & .value {
          font-size: 18px;
          & .hint {
            font-size: 14px;
            color: grey;
          }
        }
      }
    }
  }
`;

export default DashboardHomePage;
