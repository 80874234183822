import React from 'react';
import styled from 'styled-components';
import {Button, Input, Select, message, Modal, Empty} from 'antd';
import cities from '../../domain/address/city.json';
import districts from '../../domain/address/district.json';
import {EyeOutlined} from '@ant-design/icons';
import * as AppContext from '../../AppContext';
import {validDateString} from '../../domain/Validate';
import moment from 'moment';

const FILTER_TYPE_DATA = {
  RECEIVER: {
    key: 'receiver',
    fieldPrefix: 'receiver',
    name: '收件人地址',
  },
  BUYER: {
    key: 'buyer',
    fieldPrefix: 'buyer',
    name: '購買人地址',
  },
};

function getPercentageDisplay(numerator, denominator, decimal = 2) {
  return `${
    Math.round((numerator * Math.pow(10, decimal + 2)) / denominator) /
    Math.pow(10, decimal)
  }%`;
}

function cleanUpStats(stats, type) {
  let _prefix = type === FILTER_TYPE_DATA.RECEIVER.key ? 'receiver' : 'buyer';

  let allStats = stats.reduce(
    (acc, curr) => {
      return {
        count: acc.count + curr.count,
        total: acc.total + curr.total,
      };
    },
    {
      count: 0,
      total: 0,
    },
  );

  return {
    count: allStats.count,
    total: allStats.total,
    items: stats.map((s) => ({
      ...s,
      count_percent: getPercentageDisplay(s.count, allStats.count),
      total_percent: getPercentageDisplay(s.total, allStats.total),
      city: s[`${_prefix}_city`] || '自取',
      districts: s.districts.map((d) => ({
        ...d,
        district: d[`${_prefix}_district`] || '自取',
        count_percent: getPercentageDisplay(d.count, s.count),
        total_percent: getPercentageDisplay(d.total, s.total),
      })),
    })),
  };
}

function AnalysisSection(props) {
  const [filters, setFilters] = React.useState({
    created_date_from: '',
    created_date_to: '',
    city: '',
    type: FILTER_TYPE_DATA.RECEIVER.key,
  });
  const app = React.useContext(AppContext.Context);
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [data, setData] = React.useState({
    count: null,
    total: null,
    items: [],
  });

  const onSelectChange = React.useCallback(
    (field) => (value) => {
      let _nextValues = {
        [field]: value,
      };
      setFilters({
        ...filters,
        ..._nextValues,
      });
    },
    [filters],
  );

  const onSearchQueryClick = React.useCallback(async () => {
    if (!filters.created_date_from || !filters.created_date_to) {
      message.warn('請輸入日期區間！');
      return;
    }

    if (
      !validDateString(filters.created_date_from) ||
      !validDateString(filters.created_date_to) ||
      filters.created_date_from > filters.created_date_to
    ) {
      message.warn('日期區間格式有誤！');
      return;
    }

    try {
      setIsFetchingData(true);
      let {from, to} = {
        from: moment(filters.created_date_from, 'YYYY-MM-DD').valueOf(),
        to: moment(filters.created_date_to, 'YYYY-MM-DD')
          .add(1, 'days')
          .valueOf(),
      };

      let _type =
        filters.type === FILTER_TYPE_DATA.RECEIVER.key ? 'receiver' : 'buyer';
      let _filterFieldPrefix =
        filters.type === FILTER_TYPE_DATA.RECEIVER.key
          ? FILTER_TYPE_DATA.RECEIVER.fieldPrefix
          : FILTER_TYPE_DATA.BUYER.fieldPrefix;

      let _fetchQuery = {
        $and: [
          {
            created: {
              $gte: from,
            },
          },
          {
            created: {
              $lte: to,
            },
          },
        ],
      };

      if (filters.city) {
        _fetchQuery[`${_filterFieldPrefix}_city`] = filters.city;
      }

      let _statsResp = await app.actions.staffGetOrderStats({
        query: _fetchQuery,
        type: _type,
      });

      let _data = cleanUpStats(_statsResp, _type);
      setData({
        count: _data.count,
        items: _data.items,
      });
    } catch (err) {
      message.error('無法取得訂單資料');
    } finally {
      setIsFetchingData(false);
    }
  }, [filters]);

  return (
    <Wrapper>
      <div className="filters">
        <div className="inputs">
          <div className="item">
            <label>訂單成立起始日</label>
            <Input
              placeholder={'YYYY-MM-DD'}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  created_date_from: e.target.value,
                })
              }
              value={filters.created_date_from}
            />
          </div>
          <div className="item">
            <label>訂單成立結束日</label>
            <Input
              placeholder={'YYYY-MM-DD'}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  created_date_to: e.target.value,
                })
              }
              value={filters.created_date_to}
            />
          </div>
        </div>
        <div style={{display: 'flex'}}>
          <SelectInput
            style={{marginLeft: 0}}
            placeholder="依據購買/收貨地址"
            value={filters.type || undefined}
            onChange={onSelectChange('type')}>
            {[FILTER_TYPE_DATA.RECEIVER, FILTER_TYPE_DATA.BUYER].map((t) => (
              <SelectInputOption key={t.key} value={t.key}>
                {t.name}
              </SelectInputOption>
            ))}
          </SelectInput>
          <SelectInput
            allowClear
            placeholder="縣市"
            value={filters.city || undefined}
            onChange={onSelectChange('city')}>
            {cities.map((c, idx) => (
              <SelectInputOption key={idx} value={c}>
                {c}
              </SelectInputOption>
            ))}
          </SelectInput>
          <div style={{flex: 1}} />
        </div>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}} />
          <Button loading={isFetchingData} onClick={onSearchQueryClick}>
            搜尋
          </Button>
        </div>
      </div>

      <div className="results">
        {data.count !== null && (
          <>
            <p style={{textAlign: 'right'}}>
              {' '}
              共{' '}
              <span className="highlight">
                {data.count || (data.count !== 0 ? '' : 0)}
              </span>{' '}
              筆
            </p>
          </>
        )}

        <StatsWrapper>
          {data.items.length === 0 && (
            <div className="empty">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
          {data.items.map((i, idx) => (
            <div className="city" key={idx}>
              <div className="title">{i.city}</div>
              <div className="districts">
                {i.districts.map((d, didx) => (
                  <div className="district" key={didx}>
                    <div className="title">{d.district}</div>
                    <div className="value">共 {d.count} 筆</div>
                    <div className="value">{d.total} 元</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </StatsWrapper>
      </div>
    </Wrapper>
  );
}

const StatsWrapper = styled.div`
  & > .empty {
    padding: 20px 0px;
    text-align: center;
  }
  & > .city {
    & > .title {
      font-size: 18px;
    }

    & > .districts {
      padding-left: 10px;
      display: flex;
      flex-direction: column;

      & > .district {
        display: flex;
        margin-bottom: 5px;
        & > .title {
          margin-right: 15px;
        }

        & > .value {
          margin-right: 15px;
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  & > .filters {
    & > .note {
      font-size: 14px;
      color: grey;
      text-align: right;
    }
    & > .inputs {
      & > .item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        & label {
          margin-right: 10px;
          white-space: nowrap;
        }
      }
    }

    & > .actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  & > .results {
    padding: 40px 0px 10px;
    p {
      & > span.highlight {
        font-size: 24px;
      }
    }
  }
`;

const SelectInput = styled(Select)`
  margin: 5px;
  flex-basis: 100px;
  height: 40px;
  & > .ant-select-selector {
    height: 100% !important;
    align-items: center;
  }
`;

const SelectInputOption = styled(Select.Option)`
  width: 100px;
`;

export default AnalysisSection;
